import React from "react"
import { Helmet } from "react-helmet"

const Head = ({ data, title, description }) => {
  return (
    <Helmet title={`${title} | ${data.site.siteMetadata.title}`}>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
      <meta
        name="description"
        content={description || data.site.siteMetadata.description}
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap"
      />
      <html lang="en" />
    </Helmet>
  )
}

export default Head
