import React from "react"
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"

import initialTheme from "./theme"

const StylesDecorator = props => {
  let theme = createMuiTheme(initialTheme)
  theme = responsiveFontSizes(theme)

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </MuiThemeProvider>
  )
}

export default StylesDecorator
