import React from "react"
import { Link as GaLink } from "gatsby"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import MuiLink from "@material-ui/core/Link"
import Container from "@material-ui/core/Container"

import LinkedInIcon from "@material-ui/icons/LinkedIn"
import TwitterIcon from "@material-ui/icons/Twitter"
import InstagramIcon from "@material-ui/icons/Instagram"

import Grid from "@material-ui/core/Grid"
import MailOutlineIcon from "@material-ui/icons/MailOutline"

const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  gridContainer: {
    display: "grid",
    gridGap: theme.spacing(2),
    margin: `${theme.spacing(2)}px 0`,
    justifyContent: "flex-start",
    alignItems: "center",
    gridAutoFlow: "column",
  },
  icon: {
    width: "32px",
    height: "32px",
  },
  parentGridContainer: {
    display: "grid",
    gridGap: theme.spacing(4),
    gridAutoFlow: "column",
    margin: `${theme.spacing(5)}px 0`,

    [theme.breakpoints.down("xs")]: {
      gridAutoFlow: "row",
    },
  },
  contactDetail: {
    margin: theme.spacing(1, 0),
  },
  iconWrap: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  authorLink: {
    padding: `${theme.spacing(2)}px 0`,
    background: "#fee2d9",
  },
}))

const Copyright = ({ companyName }) => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <MuiLink
        component={React.forwardRef((props, ref) => (
          <GaLink innerRef={ref} {...props} />
        ))}
        color="inherit"
        to="/"
      >
        {companyName}
      </MuiLink>{" "}
      {new Date().getFullYear()}
      {"."} - All rights reserved.
    </Typography>
  )
}
const AuthorLink = () => {
  const classes = useStyles()

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className={classes.authorLink}
    >
      Design and Developed by{" "}
      <MuiLink
        rel="noopener noreferrer"
        target="blank"
        color="inherit"
        href="https://www.linkedin.com/in/watarusugimura/"
        aria-label="author site link"
      >
        Wataru Sugimura
      </MuiLink>{" "}
    </Typography>
  )
}

const Footer = ({ data }) => {
  const classes = useStyles()
  const {
    email,
    linkedInPageUrl,
    twitterUrl,
    companyName,
    instagramUrl,
  } = data.site.siteMetadata

  return (
    <>
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          <div className={classes.parentGridContainer}>
            <div>
              <Typography gutterBottom variant="h6" component="h6" align="left">
                Website Enquiry
              </Typography>
              <div className={classes.contactDetail}>
                <Grid container>
                  <Grid item>
                    <div className={classes.iconWrap}>
                      <MailOutlineIcon />
                    </div>
                  </Grid>
                  <Grid item>
                    <MuiLink
                      href={`mailto:${email}`}
                      variant="body1"
                      color="inherit"
                    >
                      {email}
                    </MuiLink>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div>
              <Typography gutterBottom variant="h6" component="h6" align="left">
                Let's Connect
              </Typography>
              <div className={classes.gridContainer}>
                {linkedInPageUrl && (
                  <MuiLink
                    color="inherit"
                    noWrap
                    href={linkedInPageUrl}
                    rel="noopener noreferrer"
                    target="blank"
                    aria-label="Linkedin"
                  >
                    <LinkedInIcon className={classes.icon} />
                  </MuiLink>
                )}

                {instagramUrl && (
                  <MuiLink
                    color="inherit"
                    noWrap
                    href={instagramUrl}
                    rel="noopener noreferrer"
                    target="blank"
                    aria-label="Instagram"
                  >
                    <InstagramIcon className={classes.icon} />
                  </MuiLink>
                )}

                {twitterUrl && (
                  <MuiLink
                    color="inherit"
                    noWrap
                    href={twitterUrl}
                    rel="noopener noreferrer"
                    target="blank"
                    aria-label="Twitter"
                  >
                    <TwitterIcon className={classes.icon} />
                  </MuiLink>
                )}
              </div>
            </div>
          </div>
        </Container>
        <Copyright companyName={companyName} />
      </footer>
      <AuthorLink />
    </>
  )
}

export default Footer
