import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import HeaderPresenter from "./header"

const Header = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      file(relativePath: { eq: "logo.jpeg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }

      site {
        siteMetadata {
          companyName
        }
      }
    }
  `)

  return <HeaderPresenter data={data} />
}

export default Header
