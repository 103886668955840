import React from "react"
import MuiLink from "@material-ui/core/Link"

import PDF from "../../../static/profile-in-japanese.pdf"

const DownloadButton = ({ children }) => {
  return (
    <MuiLink color="inherit" download href={PDF}>
      {children}
    </MuiLink>
  )
}

export default DownloadButton
