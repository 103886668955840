import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import FooterPresenter from "./footer"

const Head = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          email
          companyName
          linkedInPageUrl
          twitterUrl
          instagramUrl
        }
      }
    }
  `)

  return <FooterPresenter {...props} data={data} />
}

export default Head
