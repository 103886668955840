import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Header from "../header"
import Footer from "../footer"

import StylesDecorator from "../../styles-decorator"

const useStyles = makeStyles(theme => ({
  wrap: {
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },

  content: {
    flexGrow: 1,
  },
}))

const Layout = props => {
  const classes = useStyles()

  return (
    <div className={classes.wrap}>
      <StylesDecorator>
        <div className={classes.content}>
          <Header />
          {props.children}
        </div>
        <Footer />
      </StylesDecorator>
    </div>
  )
}

export default Layout
