const theme = {
  palette: {
    primary: {
      main: "#FEDBD0",
    },
    secondary: {
      main: "#FEEAE6",
    },
    text: {
      primary: "#442C2E",
      secondary: "#634043",
    },
  },
  typography: {
    fontFamily: [
      "Rubik",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      // "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
}

export default theme
