import React from "react"
import { Link as GaLink } from "gatsby"
import Img from "gatsby-image"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import AppBar from "@material-ui/core/AppBar"
import { makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import MuiLink from "@material-ui/core/Link"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"

import DownloadButton from "../downloadButton"

const HEADER_LINKS = [
  {
    label: "Home",
    href: "/",
  },

  {
    label: "About",
    href: "/about",
  },
  {
    label: "Contact",
    href: "/contact",
  },
]

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  icon: {
    marginRight: theme.spacing(2),
    width: "40px",
    height: "40px",
  },
  title: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
  },
  linksRoot: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  companyName: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))

const Header = props => {
  const classes = useStyles()
  const { companyName } = props.data.site.siteMetadata
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <div className={classes.root}>
      <AppBar position="relative" color="primary">
        <Toolbar>
          <MuiLink
            component={React.forwardRef((props, ref) => (
              <GaLink innerRef={ref} {...props} />
            ))}
            color="inherit"
            to="/"
            className={classes.title}
          >
            <Img
              fluid={props.data.file.childImageSharp.fluid}
              alt="Company logo"
              className={classes.icon}
            />
            <Typography
              component="h4"
              variant="button"
              color="inherit"
              noWrap
              className={classes.companyName}
            >
              {companyName}
            </Typography>
          </MuiLink>

          <Typography className={classes.linksRoot}>
            {HEADER_LINKS.map(section => (
              <MuiLink
                component={React.forwardRef((props, ref) => (
                  <GaLink innerRef={ref} {...props} />
                ))}
                color="inherit"
                noWrap
                key={section.href}
                to={section.href}
              >
                {section.label}
              </MuiLink>
            ))}
            <DownloadButton>
              {matches ? "日本語プロフィールPDF" : "プロフィール"}
            </DownloadButton>
            <Hidden smDown>
              <Button
                component={React.forwardRef((props, ref) => (
                  <GaLink innerRef={ref} {...props} />
                ))}
                variant="outlined"
                color="inherit"
                to="/free-consultation"
              >
                Get In Touch
              </Button>
            </Hidden>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Header
